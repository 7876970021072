import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import FiltersSidebarContainer from 'shared/components/FilterSidebar/FiltersSidebarContainer';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { FilterTypes } from 'usage/constants/usageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import { useInvoiceFilters } from 'invoices/contexts/InvoiceFiltersContext';
import RadioButton from 'shared/components/andtComponents/RadioButton';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import FieldFilter from 'shared/components/FieldFilter';
import { CLOUD_TYPE_IDS, UsersAccountTypeId } from 'users/constants/usersConstants';
import { propComparator } from 'shared/utils/sortUtil';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import getDisplayNameByDivisionName from 'divisions/divisionsHelpers';
import { selectStyles } from 'shared/constants/colorsConstants';
import moment from 'moment';
import Select from 'react-select';
import { formatExcludeFilterMap } from 'shared/utils/filtersUtils';
import { convertObjToMap } from 'shared/utils/apiUtil';

import { formatFilterOptionValueLabel } from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import useBillingRules from '../hooks/react-query/useBillingRules';
import {
  getDefaultFilters,
  getFiltersConfig,
  getFilterStatusTypeMapByCloudType as getFilterTypesMapByCloudType,
  getLikeFiltersStatus,
  MARGIN_TYPES_IDS,
  marginTypes,
  marginTypesByCloudType,
  validateBillingRule,
} from '../billingRulesHelpers';
import styles from './addBillingRuleModal.module.scss';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';

const CustomInputValue = ({ data, ...props }) => {
  const { label } = data;
  return (
    <div {...props}>
      <div className={styles.selectValueWrapper}>
        <div className={styles.valueLabel}>{label}</div>
      </div>
    </div>
  );
};
const formatOptionLabel = ({ label, description }) => (
  <div className={styles.selectOptionsWrapper}>
    <div className={styles.optionLabel}>{label}</div>
    <div className={styles.optionSubLabel}>{description}</div>
  </div>
);

const AddBillingRuleModal = ({ onClose, billingRuleToEdit }) => {
  const { getPageFilters, filtersValuesMap } = useInvoiceFilters();
  const { usersStore } = useRootStore();
  const [billingRule, setBillingRule] = useState({
    ...billingRuleToEdit,
    scope: billingRuleToEdit?.scope || {
      startMonth: moment().format('YYYY-MM'),
      endMonth: moment().format('YYYY-MM'),
    },
  });
  const [isOneTimeRule, setIsOneTimeRule] = useState(
    billingRuleToEdit?.scope.startMonth === billingRuleToEdit?.scope.endMonth,
  );
  const [validationErrors, setValidationErrors] = useState(null);

  const { createBillingRule, updateBillingRule } = useBillingRules();
  const { mutateAsync: handleCreateBillingRule, isLoading: isCreatingBillingRule } = createBillingRule({
    onSuccess: onClose,
  });
  const { mutateAsync: handleUpdateBillingRule, isLoading: isUpdatingBillingRule } = updateBillingRule({
    onSuccess: onClose,
  });

  const [likeFiltersStatus, setLikeFiltersStatus] = useState({
    [AwsCommonFields.ITEM_DESCRIPTION]: 1,
    ...getLikeFiltersStatus(billingRuleToEdit?.filters),
  });

  const [filterTypesMap, setFilterTypesMap] = useState(
    getFilterTypesMapByCloudType(usersStore.currDispUserCloudAccountType, billingRule?.filters),
  );

  const fieldToFieldDistincValuesMap = getPageFilters(
    PageNames.BILLING_RULES_NEW,
    usersStore.currDispUserCloudAccountType,
    [
      {
        name: AwsCommonFields.SERVICE,
        filter: () => !['AWS_SUPPORT', 'REMOVE_AWS_SUPPORT'].includes(billingRule?.margin?.type),
      },
    ],
  );

  const getSelectValues = (values, filterName) => {
    if (!values) {
      return [];
    }
    const selectedValues = values.map((value) => {
      if (typeof value === 'object') {
        return value;
      }
      const fieldValue = fieldToFieldDistincValuesMap
        .get(filterName)
        ?.find(
          (v) =>
            v === value ||
            Object.keys(v).find((key) => (filterName !== 'linkedaccid' || key !== 'accountId') && v[key] === value),
        );
      return fieldValue ? formatFilterOptionValueLabel(fieldValue, filterName) : { value, label: value };
    });
    return selectedValues;
  };

  const getSelectedOptionsMap = (filters) => {
    if (!filters) {
      return new Map();
    }
    const selectedOptionsMap = new Map();
    Object.entries(filters).forEach(([group, groupFilters]) => {
      if (!groupFilters) {
        return;
      }
      if (['includeFilters', 'excludeFilters', 'likeFilters'].includes(group)) {
        Object.entries(groupFilters).forEach(([filterName, values]) => {
          selectedOptionsMap.set(
            filterName,
            getSelectValues(
              [...(selectedOptionsMap.get(filterName)?.map((o) => o.value) || []), ...values],
              filterName,
            ),
          );
        });
      }
    });
    return selectedOptionsMap;
  };

  const [selectedFiltersMap, setSelectedFiltersMap] = useState(getSelectedOptionsMap(billingRuleToEdit?.filters));

  const getIsMultiAccountsAccUser = () => {
    const currAccountTypeId = usersStore.getCurrAccountTypeId();
    return (
      currAccountTypeId === UsersAccountTypeId.RESELLER_MULTI_ACCOUNT ||
      currAccountTypeId === UsersAccountTypeId.RESELLER_MULTI_CLOUDS_ACCOUNT ||
      currAccountTypeId === UsersAccountTypeId.M3C_ACCOUNT
    );
  };
  const getScopeListOfValues = (payerAccListOfValues) => {
    if (payerAccListOfValues && payerAccListOfValues.length && billingRule.accountId) {
      return usersStore.getDivisionValuesByPayerAccount(billingRule.accountId);
    }
    return usersStore.getDivisionValues();
  };

  const prepareLinkedAccountOptions = () => {
    const isMultiAccountsAccUser = getIsMultiAccountsAccUser();
    const allLinkedAccounts = filtersValuesMap?.get(AwsCommonFields.LINKED_ACCOUNT_NAME);
    if (isMultiAccountsAccUser) {
      return allLinkedAccounts.filter((linkedAcc) => linkedAcc.accountId === billingRule.accountId);
    }
    const customerLinkedAccountIds = usersStore.usersModel.divisionAllocatedLinkedAccounts;
    const linkedAccountOptions =
      allLinkedAccounts &&
      allLinkedAccounts.filter((linkA) => customerLinkedAccountIds.includes(linkA.linkedAccountId));
    return linkedAccountOptions;
  };

  const createLinkedAccountOptions = (linkedAccounts) => {
    if (!linkedAccounts) {
      return [];
    }
    const values = linkedAccounts.map((item) => ({ value: item.linkedAccountId, label: item.displayLabel }));
    if (values.length) {
      values.sort(propComparator('label'));
    }
    return values;
  };

  const createScopeOptions = (scopeValue) => {
    if (scopeValue) {
      return [
        {
          value: scopeValue,
          label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', scopeValue),
        },
      ];
    }
    const values = getScopeListOfValues(filtersValuesMap.get(AwsCommonFields.PAYER_ACCOUNT)).map((item) => ({
      value: item,
      label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item),
    }));
    if (values.length) {
      values.sort(propComparator('label'));
    }
    return values;
  };

  const handleFilterChange = (filterName, values) => {
    setSelectedFiltersMap(new Map([...selectedFiltersMap, [filterName, values]]));
  };

  const handleChangeFilterType = (field, subField, type) => {
    if (!subField) {
      const filterType =
        type !== OPERATORS_KEYS.LIKE && filterTypesMap.get(field) === FilterTypes.INCLUDE
          ? FilterTypes.EXCLUDE
          : FilterTypes.INCLUDE;
      setFilterTypesMap(new Map([...filterTypesMap, [field, filterType]]));
    } else if (subField) {
      setFilterTypesMap(new Map([...formatExcludeFilterMap(convertObjToMap(filterTypesMap), field, subField)]));
    }
    setLikeFiltersStatus(
      type === OPERATORS_KEYS.LIKE
        ? {
            ...likeFiltersStatus,
            [field]: +!likeFiltersStatus[field],
          }
        : {},
    );
  };

  const saveBillingRuleFilters = () => {
    const includeFilters = {};
    const excludeFilters = {};
    const likeFilters = {};
    const caseSensitive = {};
    selectedFiltersMap.forEach((values, filterName) => {
      const filterType = filterTypesMap.get(filterName);
      if (typeof filterType === 'object') {
        includeFilters[filterName] = [];
        excludeFilters[filterName] = [];
        values.forEach((subValue) => {
          const parentField = subValue.value.split(': ')[0];
          if (!filterType[parentField] || filterType[parentField] === FilterTypes.INCLUDE) {
            includeFilters[filterName] = [...includeFilters[filterName], subValue.value];
          } else {
            excludeFilters[filterName] = [...excludeFilters[filterName], subValue.value];
          }
        });
      } else if (filterType === FilterTypes.INCLUDE) {
        if (likeFiltersStatus[filterName]) {
          likeFilters[filterName] = [...values.map((v) => v.value)];
          if (likeFiltersStatus[filterName]?.caseSensitive) {
            caseSensitive[filterName] = 1;
          }
        } else {
          includeFilters[filterName] = [...values.map((v) => v.value)];
        }
      } else {
        excludeFilters[filterName] = [...values.map((v) => v.value)];
      }
    });
    setBillingRule({
      ...billingRule,
      filters: { ...billingRule.filters, includeFilters, excludeFilters, likeFilters, caseSensitive },
    });
  };

  useEffect(() => {
    saveBillingRuleFilters();
  }, [selectedFiltersMap, filterTypesMap, likeFiltersStatus]);

  useEffect(() => {
    if (validationErrors) {
      setValidationErrors(validateBillingRule(billingRule));
    }
  }, [billingRule]);

  const renderValidationError = (field) =>
    validationErrors?.[field] ? <p className={styles.validationError}>{validationErrors[field]}</p> : '';

  return (
    <CustomModal
      open
      title={`${billingRule?.ruleId ? 'Edit' : 'Create'} Billing Rule`}
      headerMode={billingRule?.ruleId ? MODAL_MODES.EDIT : MODAL_MODES.ADD}
      onClose={onClose}
      saveDisabled={validationErrors}
      saveTitle={billingRule?.ruleId ? 'Update' : 'Create'}
      onSave={() => {
        const errors = validateBillingRule(billingRule);
        if (errors) {
          setValidationErrors(errors);
          return;
        }
        if (billingRule?.ruleId) {
          handleUpdateBillingRule(billingRule);
        } else {
          billingRule.scope.level = 'customer'; // partner will be supported in the future
          handleCreateBillingRule(billingRule);
        }
      }}
      closeOnSave={false}
      isLoading={isCreatingBillingRule || isUpdatingBillingRule}
      className={styles.addBillingRuleModal}
      footerBorde
    >
      <div className={styles.content}>
        <div className={styles.billingRuleSettingsContainer}>
          <h4>Billing Rule Settings</h4>
          <label htmlFor="billing-rule-name">
            Billing Rule Name
            <Input
              value={billingRule?.description?.name}
              onChange={(e) =>
                setBillingRule({
                  ...billingRule,
                  description: { ...billingRule.description, name: e.target.value },
                })
              }
              id="billing-rule-name"
            />
            {renderValidationError('name')}
          </label>
          <label htmlFor="billing-rule-description">
            Description
            <Input
              value={billingRule?.description?.description}
              onChange={(e) =>
                setBillingRule({
                  ...billingRule,
                  description: { ...billingRule.description, description: e.target.value },
                })
              }
              id="billing-rule-description"
            />
          </label>
          <label htmlFor="billing-rule-customers">
            Customers
            <FieldFilter
              type="divNames"
              placeHolder="All"
              value={
                Array.isArray(billingRule?.scope?.customerNames)
                  ? billingRule.scope.customerNames.map((value) => ({
                      value,
                      label: getDisplayNameByDivisionName(value) || value,
                    }))
                  : billingRule?.scope?.customerNames
              }
              options={createScopeOptions()}
              handleChange={(type, value) => {
                setBillingRule({
                  ...billingRule,
                  scope: { ...billingRule.scope, customerNames: value?.map((v) => v?.value) || [] },
                });
              }}
              styles={selectStyles}
              id="billing-rule-linked-accounts"
            />
          </label>
          <label htmlFor="billing-rule-linked-accounts">
            {getLinkedAccountName(usersStore.currDispUserCloudAccountType)}s
            <FieldFilter
              type="linkedAccounts"
              placeHolder="All"
              value={billingRule?.scope?.linkedAccountIds?.map((value) => ({
                value,
                label:
                  createLinkedAccountOptions(prepareLinkedAccountOptions()).find((laOption) => laOption.value === value)
                    ?.label || value,
              }))}
              options={createLinkedAccountOptions(prepareLinkedAccountOptions())}
              handleChange={(type, value) => {
                setBillingRule({
                  ...billingRule,
                  scope: { ...billingRule.scope, linkedAccountIds: value?.map((v) => v.value || v) || [] },
                });
              }}
              styles={selectStyles}
              id="billing-rule-customers"
            />
          </label>
          {!!billingRule.scope.customerNames?.length && !!billingRule.scope.linkedAccountIds?.length && (
            <p className={styles.linkedAccountWarning}>
              <GenerateIcon iconName={ICONS.circleInfo.name} />
              The billing rules will be applied to this linked account and not to the whole customer.
            </p>
          )}
          <label className={styles.frequencyContainer} htmlFor="billing-rule-frequency">
            Frequency:
            <RadioButton
              onClick={() => {
                setIsOneTimeRule(true);
                setBillingRule({
                  ...billingRule,
                  scope: {
                    ...billingRule.scope,
                    endMonth: billingRule.scope.startMonth,
                  },
                });
              }}
              value="one-time"
              label="One Time"
              primary
              checked={isOneTimeRule}
            />
            <RadioButton
              onClick={() => {
                setIsOneTimeRule(false);
                setBillingRule({
                  ...billingRule,
                  scope: {
                    ...billingRule.scope,
                    endMonth: moment(billingRule.scope.startMonth).add(1, 'month').format('YYYY-MM'),
                  },
                });
              }}
              value="recurring"
              label="Recurring"
              primary
              checked={!isOneTimeRule}
            />
          </label>
          <label className={styles.datePicker} htmlFor="billing-rule-date-picker">
            <DatePickerFilter
              startDate={billingRule.scope.startMonth}
              endDate={billingRule.scope.endMonth}
              selectsStart
              isMaxDateNeeded={false}
              andtLook
              currPeriodGranLevel="month"
              isEndDisable={isOneTimeRule || !billingRule.scope.endMonth}
              hideEnd={isOneTimeRule}
              dateFormat="MMM YYYY"
              onDateChange={({ startDate, endDate }) => {
                setBillingRule({
                  ...billingRule,
                  scope: {
                    ...billingRule.scope,
                    startMonth: moment(startDate).format('YYYY-MM'),
                    endMonth: isOneTimeRule
                      ? moment(startDate).format('YYYY-MM')
                      : endDate
                      ? moment(endDate).format('YYYY-MM')
                      : null,
                  },
                });
                if (moment(startDate).format('YYYY-MM') === moment(endDate).format('YYYY-MM')) {
                  setIsOneTimeRule(true);
                }
              }}
            />
            {!isOneTimeRule && (
              <Checkbox
                text="No End Month"
                isChecked={!billingRule.scope.endMonth}
                onChange={(noEndMonth) =>
                  setBillingRule({
                    ...billingRule,
                    scope: {
                      ...billingRule.scope,
                      endMonth: noEndMonth
                        ? null
                        : moment(billingRule.scope.startMonth).add(1, 'month').format('YYYY-MM'),
                    },
                  })
                }
              />
            )}
          </label>
          <label htmlFor="billing-rule-margin-type">
            Margin Type
            <Select
              value={marginTypes.find((type) => type.value === billingRule?.margin?.type)}
              components={{ SingleValue: CustomInputValue }}
              formatOptionLabel={formatOptionLabel}
              options={marginTypesByCloudType[usersStore.currDispUserCloudAccountType]}
              menuPosition="fixed"
              menuPlacement="top"
              placeholder="Select"
              styles={selectStyles}
              maxMenuHeight={470}
              onChange={(selectedOption) => {
                const filters = getDefaultFilters(selectedOption.value);
                setBillingRule({
                  ...billingRule,
                  margin:
                    selectedOption.value === MARGIN_TYPES_IDS.ADD_AWS_SUPPORT
                      ? { type: selectedOption.value, resolution: 'customer', disableMinimumFee: false }
                      : { type: selectedOption.value },
                  filters,
                });
                setSelectedFiltersMap(getSelectedOptionsMap(filters));
                setFilterTypesMap(getFilterTypesMapByCloudType(usersStore.currDispUserCloudAccountType, filters));
              }}
            />
            {renderValidationError('marginType')}
          </label>
          <div className={styles.amountAndCostTypeContainer}>
            {[
              MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR,
              MARGIN_TYPES_IDS.ADD_FIXED_COST,
              MARGIN_TYPES_IDS.SET_FIXED_RATE,
              MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR,
            ].includes(billingRule?.margin?.type) && (
              <label htmlFor="billing-rule-margin-amount">
                Amount{' '}
                {[MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR, MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR].includes(
                  billingRule?.margin?.type,
                )
                  ? '%'
                  : '$'}{' '}
                (negative values for discount)
                <Input
                  type="number"
                  step={1}
                  value={
                    [MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR, MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR].includes(
                      billingRule?.margin?.type,
                    )
                      ? billingRule?.margin?.factor && parseFloat((billingRule.margin.factor * 100).toFixed(10))
                      : billingRule?.margin?.amount
                  }
                  onChange={(e) =>
                    setBillingRule({
                      ...billingRule,
                      margin: [
                        MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR,
                        MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR,
                      ].includes(billingRule?.margin?.type)
                        ? { ...billingRule.margin, factor: e.target.value / 100 }
                        : { ...billingRule.margin, amount: e.target.value },
                    })
                  }
                  placeholder={
                    [MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR, MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR].includes(
                      billingRule?.margin?.type,
                    )
                      ? '%'
                      : '$'
                  }
                  id="billing-rule-amount"
                />
                {renderValidationError('amount')}
              </label>
            )}
            {/* {[MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR, MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR].includes(
              billingRule?.margin?.type,
            ) && (
              <label htmlFor="billing-rule-cost-type">
                Cost Type
                <Select
                  value={COST_TYPES.find((type) => type.value === billingRule?.margin?.costType)}
                  options={COST_TYPES}
                  menuPlacement="top"
                  placeholder="Select"
                  styles={selectStyles}
                  onChange={(selectedOption) => {
                    setBillingRule({
                      ...billingRule,
                      margin: { ...billingRule.margin, costType: selectedOption.value },
                    });
                  }}
                />
              </label>
            )} */}
          </div>
          {[MARGIN_TYPES_IDS.ADD_FIXED_COST, MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR].includes(
            billingRule?.margin?.type,
          ) && (
            <label className={styles.customServiceContainer} htmlFor="billing-rule-margin-custom-service">
              Custom Service Name
              <Tooltip
                arrow
                title="Note: All customers on a shared account can see the custom service name under the service dropdown (the cost will be visible only to the relevant customer)."
              >
                <span className={styles.infoIcon}>
                  <GenerateIcon iconName={ICONS.circleInfo.name} />
                </span>
              </Tooltip>
              <Input
                value={billingRule.margin.customService}
                onChange={(e) =>
                  setBillingRule({
                    ...billingRule,
                    margin: { ...billingRule.margin, customService: e.target.value },
                  })
                }
                id="billing-rule-custom-service"
              />
              {renderValidationError('customService')}
            </label>
          )}
          {[MARGIN_TYPES_IDS.ADD_AWS_SUPPORT, MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT].includes(
            billingRule?.margin?.type,
          ) && (
            <label htmlFor="billing-rule-margin-plans">
              Plan{MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT === billingRule?.margin?.type ? 's' : ''}
              <Select
                value={
                  (MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT === billingRule?.margin?.type
                    ? billingRule?.margin?.plans &&
                      billingRule.margin.plans.map((plan) => ({ value: plan, label: plan }))
                    : billingRule?.margin?.plan && {
                        value: billingRule.margin.plan,
                        label: billingRule.margin.plan,
                      }) || null
                }
                options={[
                  'AWS Support [Business]',
                  'AWS Support [Developer]',
                  'AWS Support [Enterprise]',
                  'AWS Premium Support',
                ].map((value) => ({ value, label: value }))}
                onChange={(selectedOptions) =>
                  setBillingRule({
                    ...billingRule,
                    margin:
                      MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT === billingRule?.margin?.type
                        ? { ...billingRule.margin, plans: selectedOptions.map(({ value }) => value) }
                        : { ...billingRule.margin, plan: selectedOptions.value },
                  })
                }
                styles={selectStyles}
                isMulti={MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT === billingRule?.margin?.type}
                menuPlacement="top"
                placeholder="Select"
                id="billing-rule-margin-plans"
              />
              {renderValidationError('plan')}
            </label>
          )}
          {[MARGIN_TYPES_IDS.ADD_AWS_SUPPORT].includes(billingRule?.margin?.type) && (
            <label className={styles.resolutionContainer} htmlFor="billing-rule-resolution">
              Calculate AWS support tiering based on:
              <RadioButton
                onClick={() =>
                  setBillingRule({ ...billingRule, margin: { ...billingRule.margin, resolution: 'customer' } })
                }
                value="customer"
                label="Total"
                primary
                checked={billingRule?.margin?.resolution === 'customer'}
              />
              <RadioButton
                onClick={() =>
                  setBillingRule({ ...billingRule, margin: { ...billingRule.margin, resolution: 'linked_account' } })
                }
                value="linked_account"
                label="Per Linked Account"
                primary
                checked={billingRule?.margin?.resolution === 'linked_account'}
              />
            </label>
          )}
        </div>

        <div className={styles.filtersContainer}>
          <h4>Filters</h4>
          {usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && (
            <label className={styles.checkbox} htmlFor="billing-rule-exclude-aws-marketplace">
              <Checkbox
                text="Exclude AWS Marketplace"
                isChecked={billingRule?.filters?.excludeAwsMarketplace}
                onChange={() =>
                  setBillingRule({
                    ...billingRule,
                    filters: {
                      ...billingRule.filters,
                      excludeAwsMarketplace: !billingRule.filters.excludeAwsMarketplace,
                    },
                  })
                }
                primary
                isDisabled={[
                  MARGIN_TYPES_IDS.ADD_FIXED_COST,
                  MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT,
                  MARGIN_TYPES_IDS.ADD_AWS_SUPPORT,
                ].includes(billingRule?.margin?.type)}
              />
            </label>
          )}
          {[MARGIN_TYPES_IDS.ADD_AWS_SUPPORT].includes(billingRule?.margin?.type) &&
            usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && (
              <label className={styles.checkbox} htmlFor="billing-rule-disable-minimum-fee">
                <Checkbox
                  text="Disable Minimum Fee"
                  isChecked={billingRule?.margin.disableMinimumFee}
                  onChange={() =>
                    setBillingRule({
                      ...billingRule,
                      margin: {
                        ...billingRule.margin,
                        disableMinimumFee: !billingRule.margin.disableMinimumFee,
                      },
                    })
                  }
                  primary
                />
              </label>
            )}
          <FiltersSidebarContainer
            isOpen
            selectedOptionsMap={selectedFiltersMap}
            excludedFiltersStatusMap={filterTypesMap}
            fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
            currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
            handleChangeFilterType={handleChangeFilterType}
            handleFilterChange={handleFilterChange}
            handleRemoveFieldFromFiltersValuesMap={(field) => {
              setSelectedFiltersMap(Array.from(selectedFiltersMap).filter((f) => f.field !== field));
            }}
            selectedCostTypes={selectedFiltersMap.get(AwsCommonFields.COST_TYPE)}
            handleCostTypeChange={handleFilterChange}
            handleCloudFrontRegionChange={handleFilterChange}
            showTitle={false}
            className={styles.billingRuleFilters}
            likeOperator
            hideSearch
            likeFiltersStatus={likeFiltersStatus}
            likeCaseConfig={{
              get: (field) => likeFiltersStatus[field]?.caseSensitive,
              set: (field, value) => {
                setLikeFiltersStatus({
                  ...likeFiltersStatus,
                  [field]: { ...likeFiltersStatus[field], caseSensitive: +value },
                });
              },
            }}
            fieldToFieldDistincValuesProps={getFiltersConfig({
              conjunctionConfig: {
                set: (key, flag) => {
                  setBillingRule({
                    ...billingRule,
                    filters: {
                      ...billingRule.filters,
                      conjunction: {
                        ...billingRule.filters.conjunction,
                        [key]: flag,
                      },
                    },
                  });
                },
                get: (key) => billingRule.filters.conjunction?.[key],
              },
              marginType: billingRule?.margin?.type,
            })}
            filtersStatusMap={selectedFiltersMap}
            isCostTypeFilter
            isBackendQuery
            disableCostTypeExclude={false}
            isCloudFrontRegionFilter={usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS}
            isDisabled={[MARGIN_TYPES_IDS.ADD_FIXED_COST, MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT].includes(
              billingRule?.margin?.type,
            )}
          />
        </div>
      </div>
    </CustomModal>
  );
};

AddBillingRuleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  billingRuleToEdit: PropTypes.object,
};

export default AddBillingRuleModal;

import { useMutation, useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import { handleError } from './helperFunctions';
import {
  createDataAccessCostCenter,
  deleteDataAccessCostCenter,
  fetchDataAccessCostCenterAccounts,
  fetchDataAccessCostCenters,
  fetchDataAccessCostCenterUnassignedAccounts,
  fetchDataAccessPayerAccounts,
  fetchDataAccessResellerCustomers,
  fetchPayerLinkedAccounts,
  updateDataAccessCostCenter,
  updateLinkedAccountData,
} from './apiDataAccessAccounts';
import { toast } from 'react-toastify';

export default function useDataAccessAccounts(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const manageAccountsQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries({ queryKey: manageAccountsQueryKey }),
    reset: () => queryClient.resetQueries({ queryKey: manageAccountsQueryKey }),
    fetchDataAccessPayerAccounts: () =>
      useQuery({
        queryKey: [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS],
        queryFn: () => fetchDataAccessPayerAccounts(),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchPayerLinkedAccounts: (payerId) =>
      useQuery({
        queryKey: [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS, payerId],
        queryFn: () => fetchPayerLinkedAccounts(payerId),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchDataAccessCostCenters: () =>
      useQuery({
        queryKey: [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS],
        queryFn: fetchDataAccessCostCenters,
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchCostCenterUnassignedAccounts: (costCenterId) =>
      useQuery({
        queryKey: [
          ...manageAccountsQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS,
          costCenterId,
          'unassigned',
        ],
        queryFn: () => fetchDataAccessCostCenterUnassignedAccounts(costCenterId),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: () =>
          toast.error('Error fetching cost center unassigned accounts data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
    fetchCostCenterAccountsData: (costCenterId) =>
      useQuery({
        queryKey: [
          ...manageAccountsQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS,
          costCenterId,
        ],
        queryFn: () => costCenterId && fetchDataAccessCostCenterAccounts(costCenterId),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: () =>
          toast.error('Error fetching cost center accounts data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
    fetchDataAccessResellerCustomers: () =>
      useQuery({
        queryKey: [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_RESELLER_CUSTOMERS],
        queryFn: () => fetchDataAccessResellerCustomers(),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    createDataAccessCostCenter: useMutation({
      mutationFn: ({ costCenter, accountsPayload }) => createDataAccessCostCenter({ costCenter, accountsPayload }),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS],
        });
        await queryClient.invalidateQueries({
          queryKey: [
            ...manageAccountsQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES,
            userAccountKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS,
          ],
        });
        await queryClient.invalidateQueries({
          queryKey: [apiConstants.QUERY_KEYS.COST_CENTERS],
        });
      },
      onError: handleError,
    }),
    updateDataAccessCostCenter: useMutation({
      mutationFn: ({ costCenterId, costCenterAccountsData }) =>
        updateDataAccessCostCenter({ costCenterId, costCenterAccountsData }),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS],
        });
        await queryClient.invalidateQueries({
          queryKey: [
            ...manageAccountsQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES,
            userAccountKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS,
          ],
        });
      },
      onError: handleError,
    }),
    deleteDataAccessCostCenter: useMutation({
      mutationFn: (costCenterId) => deleteDataAccessCostCenter(costCenterId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS],
        });
        await queryClient.invalidateQueries({
          queryKey: [
            ...manageAccountsQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES,
            userAccountKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS,
          ],
        });
      },
      onError: handleError,
    }),
    updateLinkedAccountData: useMutation({
      mutationFn: ({ payerAccountId, linkedAccountId, linkedAccountData }) =>
        updateLinkedAccountData({ payerAccountId, linkedAccountId, linkedAccountData }),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS],
        });
      },
      onError: handleError,
    }),
  };
}

import { K8S_POD_LABELS } from "./appConstants.js";

export class GcpServiceNames {
  static VM = 'Virtual Machine';
  static IP = 'IP';
}

export class GcpCommonFields {
  static NONE = 'none';
  static REGION = 'region';
  static K8S_REGION = 'k8sregion';
  static LINKED_ACCOUNT_NAME = 'linkedaccname';
  static LINKED_ACCOUNT_ID = 'linkedaccid';
  static ENVIRONMENT = 'environment';
  static DEVELOPMENT = 'development';
  static PRODUCTION = 'production';
  static STAGING = 'staging';
  static TESTING = 'testing';
  static SERVICE = 'service';
  static OPERATION = 'operation';
  static TYPE = 'type';
  static POD = 'pod';
  static ACCOUNT_TAGS = 'accounttags';
  static ACCOUNT_TAGS_KEYS = 'accounttags_keys';
  static RESOURCE = 'resource';
  static RESOURCE_ID = 'resourceid';
  static INSTANCE_TYPE = 'instancetype';
  static K8S_INSTANCE_TYPE = 'k8sinstancetype';
  static USAGE_DATE = 'usagedate';
  static GROUP_BY = 'groupby';
  static TOTAL_COST = 'totalcost';
  static TOTAL_USAGE = 'totalhours';
  static FAMILY_TYPE = 'familytype';
  static QUANTITY_TYPE = 'quantitytype';
  static OS = 'os';
  static DIVISION = 'division';
  static DIVISION_ID = 'divisionid';
  static TAGS = 'tags';
  static TAG = 'tag';
  static CATEGORIES = 'categories';
  static BUSINESS_MAPPING = 'businessmapping';
  static BUSINESS_MAPPING_VIEWPOINTS = 'businessmappingviewpoints';
  static CATEGORY = 'category';
  static USAGE_TYPE = 'usagetype';
  static CUSTOM_TAGS = 'customtags';
  static CUSTOM_TAGS_KEYS = 'customtags_keys';
  static COST_TYPE_DESCRIPTION = 'costtypedescription';
  static NODE_GROUP = 'nodegroup';
  static CLUSTER = 'cluster';
  static COST_TYPE = 'costtype';
  static NAMESPACE = 'namespace';
  static K8S_CUSTOM_TAGS = 'k8scustomtags';
  static K8S_LABEL_TAGS = 'labels';
  static PAYER_ACCOUNT = 'payeraccount';
  static PAYER_ACCOUNT_NAME = 'accountName';
  static SUB_VIEWS_CUSTOM_TAGS = 'subviewscustomtags';
}

export const GCPCommonFieldLabels = new Map([
  ['Linked Account', 'Projects'],
  [GcpCommonFields.LINKED_ACCOUNT_ID, 'Projects'],
  [GcpCommonFields.LINKED_ACCOUNT_NAME, 'Projects'],
]);

export const gcpCommonFieldToDisplayField = new Map([
  [GcpCommonFields.NONE, '- None -'],
  [GcpCommonFields.USAGE_DATE, 'Date'],
  [GcpCommonFields.REGION, 'Region'],
  [GcpCommonFields.K8S_REGION, 'Region'],
  [GcpCommonFields.TYPE, 'Type'],
  [GcpCommonFields.ENVIRONMENT, 'Environments'],
  [GcpCommonFields.DEVELOPMENT, 'Development'],
  [GcpCommonFields.PRODUCTION, 'Production'],
  [GcpCommonFields.STAGING, 'Staging'],
  [GcpCommonFields.ACCOUNT_TAGS, 'Enrichment Tags'],
  [GcpCommonFields.ACCOUNT_TAGS_KEYS, 'Enrichment Tags'],
  [GcpCommonFields.TESTING, 'Testing'],
  [GcpCommonFields.SERVICE, 'Service'],
  [GcpCommonFields.OPERATION, 'Operation'],
  [GcpCommonFields.RESOURCE, 'Resource'],
  [GcpCommonFields.RESOURCE_ID, 'Resource'],
  [GcpCommonFields.INSTANCE_TYPE, 'Instance Type'],
  [GcpCommonFields.K8S_INSTANCE_TYPE, 'Instance Type'],
  [GcpCommonFields.FAMILY_TYPE, 'Family Type'],
  [GcpCommonFields.QUANTITY_TYPE, 'Quantity Type'],
  [GcpCommonFields.OS, 'Operation System'],
  [GcpCommonFields.PAYER_ACCOUNT, 'Payer Account'],
  [GcpCommonFields.PAYER_ACCOUNT_NAME, 'Payer Account Name'],
  [GcpCommonFields.LINKED_ACCOUNT_NAME, 'Project'],
  [GcpCommonFields.LINKED_ACCOUNT_ID, 'Project'],
  [GcpCommonFields.DIVISION, 'Cost Center'],
  [GcpCommonFields.DIVISION_ID, 'Cost Center ID'],
  [GcpCommonFields.COST_TYPE, 'Cost Type'],

  [GcpCommonFields.CUSTOM_TAGS_KEYS, K8S_POD_LABELS],
  [GcpCommonFields.K8S_CUSTOM_TAGS, K8S_POD_LABELS],

  [GcpCommonFields.COST_TYPE_DESCRIPTION, 'Cost Type Description'],
  [GcpCommonFields.NODE_GROUP, 'Node Group'],
  [GcpCommonFields.CLUSTER, 'Cluster'],
  [GcpCommonFields.NAMESPACE, 'Namespace'],

  [GcpCommonFields.TAGS, K8S_POD_LABELS],
  [GcpCommonFields.CUSTOM_TAGS, K8S_POD_LABELS],
  [GcpCommonFields.TAG, 'Label'],

  [GcpCommonFields.CATEGORIES, 'Categories'],
  [GcpCommonFields.BUSINESS_MAPPING, 'Business Mapping'],
  [GcpCommonFields.BUSINESS_MAPPING_VIEWPOINTS, 'Business Mapping Viewpoints'],
  [GcpCommonFields.CATEGORY, 'Category'],
  ['AlertsAllAccounts', 'Any Project'],

  [GcpCommonFields.K8S_LABEL_TAGS, K8S_POD_LABELS],
  [GcpCommonFields.SUB_VIEWS_CUSTOM_TAGS, 'Sub Views'],

  [GcpCommonFields.POD, 'Pod'],
]);

import React, {useEffect, useMemo} from 'react';
import classNames from 'classnames';
import { palette } from 'shared/constants/colorsConstants.js';
import { PANELS, PANELS_TYPES} from 'app/containers/MainDashboard/consts.js';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import Spinner, {SPINNER_SIZES} from 'shared/components/andtComponents/Spinner.jsx';
import {
    aggregateCostsByDate,
    getFirstDayOfMonth,
    getGroupSettingByAccount
} from 'app/containers/MainDashboard/utils.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import { CustomizedAxisTick } from 'app/containers/MainDashboard/panels/HistoryWithForecast/CustomizedAxisTick.jsx';
import moment from 'moment';
import DateFilter from 'shared/modules/dateFilter.js';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import ForecastHeader from 'app/containers/MainDashboard/panels/HistoryWithForecast/ForecastHeader.jsx';
import useForecastData from 'app/containers/MainDashboard/hooks/react-query/useForecastData.js';
import AreasChart from 'shared/components/dashboardCharts/AreasChart/AreasChart.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { DailyChartCustomTooltip } from "./DailyChartCustomTooltip.jsx";
import mainStyles from '../../mainDashboardCommon.module.scss';

const getMaxValue = (data) => {
    const maxValue = data?.reduce((max, currVal) => {
        const currMax = Math.max(currVal.currMonth || 0, currVal.prevMonth || 0, currVal.currExpected || 0);
        return currMax > max ? currMax : max;
    }, 1000);
    return maxValue || 1000;
};


const AreasGradient = (<defs>
    <linearGradient id="colorPrevMonth" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor={palette.blue[400]} stopOpacity={0}/>
        <stop offset="100%" stopColor={palette.blue[400]} stopOpacity={0.4}/>
    </linearGradient>
    <linearGradient id="colorCurrentMonth" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor={palette.blue[500]} stopOpacity={0}/>
        <stop offset="100%" stopColor={palette.blue[500]} stopOpacity={0.4}/>
    </linearGradient>
    <linearGradient id="colorForecastMonth" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor={palette.azure[500]} stopOpacity={0.4}/>
        <stop offset="100%" stopColor={palette.azure[500]} stopOpacity={0}/>
    </linearGradient>
    <pattern id="patternForecastMonth" patternUnits="userSpaceOnUse" width="16" height="16">
        <rect width="100%" height="100%" fill="url(#colorForecastMonth)"/>
        <path d="M 0 16 L 16 0" stroke="white" strokeWidth="0.8"/>
    </pattern>
</defs>);

const dataKeys = ['prevMonth', 'currMonth', 'currExpected'];

const dataProperties = {
    prevMonth: { name: 'Previous Month', stroke: palette.blue[400], fill: 'url(#colorPrevMonth)'},
    currMonth: { name: 'Current Month', stroke: palette.blue[500], fill: 'url(#colorCurrentMonth)'},
    currExpected: { name: 'Forecast Months', stroke: palette.azure[500], fill: 'url(#patternForecastMonth)'}
}
const HistoryWithForecast = () => {
    const { numStrAbriviaionByDisplayMetric } = useUserSettingsContext();
    const { updatePanelLoadingState, dateFromLastMonth } = useMainDashboardContext();
    const { usersStore } = useRootStore();
    const [forecastNumMonth, setForecastNumMonth] = React.useState(1);
    const cueHook = useCueData();
    const forecastHook = useForecastData();

    const params = {
        ...PANELS[PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST].params,
        start: getFirstDayOfMonth(dateFromLastMonth),
        end: moment(DateFilter.getDate()).format('YYYY-MM-DD'),
        groupBy: getGroupSettingByAccount(usersStore)
    };
    const {data, isLoading: isHistoryDataLoading} = cueHook.fetchCueData(params);
    const {data: forecastData, isLoading: isForecastDataLoading} = forecastHook.fetchForecastData(forecastNumMonth);

    const isLoading = isHistoryDataLoading || isForecastDataLoading;

    const chartData = useMemo(() => {
        if (!data) return [];
        //calculate prev month data
        const previousMonth = data.filter((d) => d.usage_date < getFirstDayOfMonth(DateFilter.getDate()));
        const prevData = aggregateCostsByDate(previousMonth, 'prevMonth');

        //marge the last day in previous month with first day in current month
     if(prevData.length  > 0 ) {
         prevData[prevData.length - 1].currMonth = prevData[prevData.length - 1].prevMonth;
     }
     //calculate current month data
     const currentMonth = data.filter((d) => d.usage_date >= getFirstDayOfMonth(DateFilter.getDate()));
     const currentData = aggregateCostsByDate(currentMonth, 'currMonth');

     const combinedData = [...prevData, ...currentData, ...(forecastData?.forecast || [])];

    // Add accumulated field for each month
    const accumulatedByMonth = {};
    combinedData.forEach(item => {
      const month = moment(item.fullDate).format('YYYY-MM');
      if (!accumulatedByMonth[month]) {
         accumulatedByMonth[month] = 0;
      }
      accumulatedByMonth[month] += (+item.prevMonth || 0) + (+item.currMonth || 0) + (+item.currExpected || 0);
      item.accumulated = accumulatedByMonth[month];
    });

    return combinedData;

    },[data, forecastData]);


    useEffect(() => {
        updatePanelLoadingState(PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST, isLoading);
    },[isLoading]);


    const customTicks = (props) => <CustomizedAxisTick {...props} data={chartData} />;

    const maxValue = getMaxValue(chartData) * 10;

    return (
      <div className={classNames(mainStyles.panelWrapper, mainStyles.halfWidthPanelWrapper)}>
        {isLoading ? <Spinner className={mainStyles.spinner} size={SPINNER_SIZES.MEDIUM}/>  :
           (<>
              <ForecastHeader title={PANELS[PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST].title}
                              params={params}
                              forecastMonths={[{value: 1, label: '1M'},{value: 3, label: '3M'}]}
                              forecastNumMonth={forecastNumMonth}
                              setForecastNumMonth={setForecastNumMonth} />
               <AreasChart data={chartData}
                           height={300}
                           dataKeys={dataKeys}
                           specialGradient={AreasGradient}
                           dataProperties={dataProperties}
                           specialLegends={['currExpected']}
                           tooltipComponent={<DailyChartCustomTooltip />}
                           axisSettings={{xCustomTick: customTicks, yTickFormatter: (value) => numStrAbriviaionByDisplayMetric(maxValue, value, 'cost')}}/>
                </>)
            }
        </div>
    );
};

export default HistoryWithForecast;

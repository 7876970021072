import React, {useEffect, useMemo} from 'react';
import moment from 'moment';
import { PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import DateFilter from 'shared/modules/dateFilter.js';
import { Routes } from 'shared/constants/routes.js';
import { getFirstDayOfMonth, getSearchParamsPanel, sumTotalCost } from 'app/containers/MainDashboard/utils.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import UsageCost from '../components/UsageCost.jsx';
import styles from './costPanels.module.scss';


const MtdCost = () => {
    const { updatePanelLoadingState, dateFromLastMonth, getDynamicFilters } = useMainDashboardContext();
    const cueHook = useCueData();
    const { appStore } = useRootStore();

    const mtdCostParams = {
        ...PANELS[PANELS_TYPES.MTD_COST].params,
        start: getFirstDayOfMonth(moment(DateFilter.getDate())),
        end: moment(DateFilter.getDate()).format('YYYY-MM-DD'),
        ...getDynamicFilters(),
    };
    const { data: mtdData, isLoading: isMtdLoading } = cueHook.fetchCueData(mtdCostParams);
    const { data:previousMtdData, isLoading: isPreviousMtdLoading } = cueHook.fetchCueData({
        ...PANELS[PANELS_TYPES.MTD_COST].params,
           start: getFirstDayOfMonth(dateFromLastMonth),
           end: dateFromLastMonth.format('YYYY-MM-DD'),
        ...getDynamicFilters(),
    });

    const isLoading = isMtdLoading || isPreviousMtdLoading;
    const mtdCost = useMemo(() => sumTotalCost(mtdData),[mtdData]);

    const previousMtdCost = useMemo(() => sumTotalCost(previousMtdData),[previousMtdData])

    const lastMonthPercent = useMemo(() => ((previousMtdCost-mtdCost) * 100) / previousMtdCost,[previousMtdCost, mtdCost]);

    useEffect(() => {
        updatePanelLoadingState(PANELS_TYPES.MTD_COST, isLoading);
    }, [isLoading]);

    return (
        <div className={styles.costPanel}>
            {isLoading ? <Spinner className={styles.spinner} size={SPINNER_SIZES.MEDIUM}/> :
                (<UsageCost
                    title={PANELS[PANELS_TYPES.MTD_COST].title}
                    value={mtdCost}
                    moreDetails={{title: PANELS[PANELS_TYPES.MTD_COST].secondTitle, value: previousMtdCost}}
                    percent={lastMonthPercent * -1}
                    showIcon
                    infoValue={PANELS[PANELS_TYPES.MTD_COST].info}
                    navigateTo={{path: Routes.COST_USAGE_EXPLORER, searchParams: getSearchParamsPanel(mtdCostParams, appStore.isPpApplied)}}
                    showPercent
                    tooltip="Open Cost & Usage Explorer page"
                />)}
        </div>
    );
};

export default MtdCost;

import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { removeDecPoint } from 'shared/utils/strUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import PercentTag from 'app/containers/MainDashboard/components/PercentTag.jsx';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './usageCost.module.scss';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';

const UsageCost = memo(
  ({
     containerClassName = '',
     withHeader = true,
     title,
     showIcon = false,
     infoValue = '',
     value,
     showPercent = false,
     percent,
     overrideCurrency = null,
     navigateTo = null,
     moreDetails,
     tooltip,
   }) => {
    const { getCurrencyNumber } = useUserSettingsContext();
    const Wrapper = navigateTo ? Link : 'div';
    const wrapperProps = navigateTo
      ? {
        to: {
          pathname: `${navigateTo.path}`,
          search: navigateTo.searchParams,
        },
        rel: 'stylesheet',
        className: classNames(!showPercent && styles.navigateMargin),
        'automation-id': `investigate-${navigateTo?.path}`,
      }
      : {};

    return (
      <div className={classNames(styles.usageWrapper, containerClassName)}>
        <Tooltip
          placement="top"
          title={tooltip ? <span className={styles.tooltipText}>{tooltip}</span> : null}
          arrow
          classes={{ tooltip: tooltipStyles.whiteTooltip, arrow: tooltipStyles.whiteArrow }}
        >
          <Wrapper {...wrapperProps}>
            <div className={styles.savingPanel}>
              {withHeader ? (
                <div className={styles.headerWrapper}>
                  <div className={styles.mainHeader}>
                    <h5 className={styles.description}>{title}</h5>
                    {showIcon ? (
                      <InfoPopover
                        mode="outline"
                        className={{
                          icon: styles.infoIcon,
                          tooltip: tooltipStyles.whiteTooltip,
                          arrow: tooltipStyles.whiteArrow,
                        }}
                      >
                        <span className={styles.tooltipText}>{infoValue}</span>
                      </InfoPopover>
                    ) : null}
                  </div>
                  {moreDetails && <h5 className={styles.secondHeader}>{moreDetails.title}</h5>}
                </div>
              ) : null}
              <div className={styles.cardWrapper}>
                <div className={styles.valueWrapper}>
                  <h2 className={classNames(styles.title, Array.isArray(value) ? styles.fullWidth : '')}>
                    {!Array.isArray(value) ? (
                      <span>
                        {value ? getCurrencyNumber(removeDecPoint(value, 10), 0, {}, overrideCurrency) : '-'}
                      </span>
                    ) : (
                      value.map((item) => (
                        <div key={item.description} className={styles.valueRow}>
                          <div>
                            <span>{item.value}</span>
                            <span className={styles.usageDescription}>{item.description}</span>
                          </div>
                          {item.navigateTo && (
                              <GenerateIcon
                                iconName={ICONS.chevronRight.name}
                                automation-id="investigate-icon"
                                className={styles.arrowIcon}
                              />
                          )}
                        </div>
                      ))
                    )}
                  </h2>
                  {moreDetails && (
                    <div className={styles.secondValue}>
                      {moreDetails.value
                        ? `${getCurrencyNumber(removeDecPoint(moreDetails.value, 10), 0, {}, overrideCurrency)}`
                        : '-'}
                    </div>
                  )}
                </div>
                <div className={classNames(styles.detailsWrapper, (!showPercent || !moreDetails) && styles.marginTop)}>
                  <div className={classNames(!navigateTo && styles.percentMargin)}>
                    {showPercent && percent ? <PercentTag percent={percent} /> : null}
                  </div>
                  {navigateTo && !Array.isArray(value) && (
                    <GenerateIcon
                      iconName={ICONS.chevronRight.name}
                      className={styles.arrowIcon}
                      automation-id="investigate-icon"
                    />
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </Tooltip>
      </div>
    );
  }
);

UsageCost.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
  percent: PropTypes.string,
  infoValue: PropTypes.string,
  showPercent: PropTypes.bool,
  showIcon: PropTypes.bool,
  containerClassName: PropTypes.string,
  withHeader: PropTypes.bool,
  overrideCurrency: PropTypes.string,
  navigateTo: PropTypes.object,
  tooltip: PropTypes.string,
  moreDetails: PropTypes.object,
};

export default UsageCost;

UsageCost.displayName = 'UsageCost';

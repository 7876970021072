import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

export default function MenuCategory({
  title,
  children,
  id = '',
  onClick = null,
  key = '',
  classes,
  initial = false,
  isHierarchy = false,
}) {
  const [isOpen, setIsOpen] = useState(initial);

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
    chevronClickHandler(event);
  };

  const chevronClickHandler = (event) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const renderChevron = () => {
    return (
      <div className={classes.chevronIconContainer} onClick={(event) => isHierarchy && chevronClickHandler(event)}>
        <GenerateIcon
          iconName={isOpen ? ICONS.chevronDown.name : ICONS.chevronRight.name}
          className={classes.chevronIcon}
        />
      </div>
    );
  };

  return (
    <div
      key={key}
      role="button"
      id={id}
      className={classes.menuCategoryEnv}
      onClick={(event) => !isHierarchy && handleClick(event)}
    >
      <div className={classnames(classes.menuCategory, { [classes.hierarchy]: isHierarchy })}>
        {isHierarchy ? renderChevron() : null}
        <span className={classes.noWrap} onClick={(event) => isHierarchy && handleClick(event)}>
          {title}
        </span>
        {!isHierarchy ? renderChevron() : null}
      </div>
      <Collapse isOpen={isOpen}>
        <ul>
          <div>{children}</div>
        </ul>
      </Collapse>
    </div>
  );
}

MenuCategory.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]).isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  key: PropTypes.string,
  classes: PropTypes.object.isRequired,
  initial: PropTypes.bool,
  isHierarchy: PropTypes.bool,
};

import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { STALE_TIME } from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { API } from 'shared/utils/apiMiddleware.js';

export const getForecastData = async (numMonth) => {
  return await API.get('billings', numMonth ? `/api/v1/dashboards/forecast-dashboard-data?numMonth=${numMonth}` : '/api/v1/dashboards/forecast-dashboard-data');
};

export default function useForecastData(isEnabled = true) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [apiConstants.QUERY_KEYS.MAIN_DASHBOARD, apiConstants.QUERY_KEYS.FORECAST_DATA, userAccountKey];

  const useFetchForecastData = (numMonth) => {
    return useQuery({
      queryKey: [...queryKey, numMonth],
      queryFn: () => getForecastData(numMonth),
      retry: false,
      staleTime: STALE_TIME,
      enabled: isEnabled,
      keepPreviousData: true,
    });
  };

  return {
    invalidate: (queryKeyToInvalidate) =>
      queryClient.invalidateQueries(queryKeyToInvalidate ? [...queryKey] : queryKey),
    reset: (queryKeyToInvalidate) =>
      queryClient.resetQueries(queryKeyToInvalidate ? { queryKey: [...queryKey] } : { queryKey }),
    fetchForecastData: useFetchForecastData,
  };
}

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import UsageCost from '../components/UsageCost.jsx';
import { getInitialFiltersStateCopy } from 'recommendationsNew/components/heatMap/heatMapFilters/filterStates.js';
import useRecsList from 'recommendationsNew/hooks/react-query/useRecsList.js';
import { FILTERS } from 'recommendationsNew/consts.js';
import { Routes } from 'shared/constants/routes.js';
import moment from 'moment';
import DateFilter from 'shared/modules/dateFilter.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import styles from './costPanels.module.scss';

const OpenRecommendations = () => {
  const { updatePanelLoadingState } = useMainDashboardContext();
  const recommendationsListHook = useRecsList();
  const today = moment(DateFilter.getDate()).format('YYYY-MM-DD');
  const yesterday = moment(DateFilter.getDate()).subtract(1, 'days').format('YYYY-MM-DD');

  const { data: openRecommendationsToday, isLoading: isTodayRecsLoading } =
    recommendationsListHook.fetchRecommendationsTotal({
      filters: {
        ...getInitialFiltersStateCopy(),
        [FILTERS.OPEN_RECS_CREATION_DATE.id]: {
          from: today,
          to: today,
        },
      },
    });
  const { data: openRecommendationsYesterday, isLoading: isYesterdayRecsLoading } =
    recommendationsListHook.fetchRecommendationsTotal({
      filters: {
        ...getInitialFiltersStateCopy(),
        [FILTERS.OPEN_RECS_CREATION_DATE.id]: {
          from: yesterday,
          to: yesterday,
        },
      },
    });

  const isLoading = isTodayRecsLoading || isYesterdayRecsLoading;
  const recommendationsValues = [
    { value: openRecommendationsToday || 0, description: 'Today' },
    {
      value: openRecommendationsYesterday || 0,
      description: 'Yesterday',
      navigateTo: true,
    },
  ];

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.OPEN_RECOMMENDATIONS, isLoading);
  }, [isLoading]);

  return (
    <div className={classNames(styles.costPanel, styles.limitWidth)}>
      {isLoading ? (
        <Spinner className={styles.spinner} size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <UsageCost
          title={PANELS[PANELS_TYPES.OPEN_RECOMMENDATIONS].title}
          value={recommendationsValues}
          isCurrency={false}
          navigateTo={{ path: `${Routes.RECOMMENDATION_EXPLORER}` }}
          tooltip="Open Waste Detector page"
        />
      )}
    </div>
  );
};

export default OpenRecommendations;
